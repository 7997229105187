import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import * as api from '@/api'
import { getCookie, removeCookie } from '@/api/cookie'
import { UserModel } from '@/models/user'
import { MerchantClass } from '@/models/merchant'

export const useAuthStore = defineStore('auth', () => {
  const checked = ref<boolean>(false)
  const user = ref<UserModel | null>(null)
  const merchant = ref<MerchantClass | null>(null)
  const premiumBanks: string[] = [
    'BANK JATIM',
    'BNI 46',
    'DKI',
    'BANK BJB',
    'BRI',
    'JATENG',
    'B. NAGARI',
    'BPD BALI',
    'MANDIRI',
    'BANK SYARIAH INDONESIA',
    'BCA',
  ]

  const userLogin = computed(() => user.value !== null)
  const userActive = computed(() => user.value?.enabled === true)
  const userEmailVerified = computed(
    () => user.value?.email_verified_at != null,
  )
  const hasMerchant = computed(() => user.value?.merchant_id != null)
  const canRegisterMerchantPremium = computed(() =>
    premiumBanks.includes(merchant.value?.bank_account['bank'] ?? ''),
  )
  const premiumBankOptions = computed(() => premiumBanks.join(', '))

  const fetch = async (force: boolean = false) => {
    const env = import.meta.env.VITE_APP_ENV
    const uid = getCookie(`seller_read${env ? `_${env}` : ''}`)
    if (!uid) {
      if (!checked.value) {
        await api.csrfCookie()
        checked.value = true
        return fetch()
      }
      return
    }
    if (force || !user.value) {
      const data = await api.check<UserModel>()
      user.value = data
    }
  }

  const fetchMerchant = async (force: boolean = false) => {
    if (!user.value?.merchant_id || (!force && merchant.value)) return

    const response = await api.getProfile<MerchantClass>()
    if (response) {
      merchant.value = new MerchantClass(response)
    }
  }

  const updateUser = (payload: UserModel) => {
    user.value = payload
  }

  const logout = async () => {
    await api.logout()
    clearUser()
  }
  const clearUser = async () => {
    user.value = null

    const env = import.meta.env.VITE_APP_ENV
    removeCookie(`seller_read${env ? `_${env}` : ''}`)
  }

  return {
    user,
    userLogin,
    userActive,
    userEmailVerified,
    merchant,
    hasMerchant,
    canRegisterMerchantPremium,
    premiumBankOptions,

    fetch,
    fetchMerchant,
    updateUser,
    logout,
    clearUser,
  }
})
