import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      guard: 'guest',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      guard: 'guest',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      guard: 'guest',
    },
  },
  {
    path: '/password/reset',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      guard: 'guest',
    },
  },
  {
    path: '/seller-email/verify',
    name: 'seller-verification',
    component: () => import('@/views/auth/Verification.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/merchant-email/verify',
    name: 'merchant-verification',
    component: () => import('@/views/auth/Verification.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/products/Index.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/products/add',
    name: 'product-add',
    component: () => import('@/views/products/Add.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/products/:id',
    name: 'product-detail',
    component: () => import('@/views/products/Id.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/product-storages',
    name: 'product-storages',
    component: () => import('@/views/product-storages/Index.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/product-packages',
    name: 'product-packages',
    component: () => import('@/views/product-packages/Index.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/product-packages/add',
    name: 'product-packages-add',
    component: () => import('@/views/product-packages/Add.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/product-packages/:id',
    name: 'product-package-detail',
    component: () => import('@/views/product-packages/Id.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/product-discussion',
    name: 'product-discussion',
    component: () => import('@/views/product-discussion/Index.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/product-discussion/:id',
    name: 'product-discussion-detail',
    component: () => import('@/views/product-discussion/Id.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/orders/Index.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/order/:id',
    redirect: (to) => `/orders/${to.params.id}`,
  },
  {
    path: '/orders/:id',
    name: 'order-detail',
    component: () => import('@/views/orders/Id.vue'),
    meta: {
      guard: 'auth',
    },
  },
  // {
  //   path: '/comparisons',
  //   name: 'comparisons',
  //   component: () => import('@/views/comparisons/Index.vue'),
  //   meta: {
  //     guard: 'auth',
  //   },
  // },
  {
    path: '/negotiations',
    name: 'negotiations',
    component: () => import('@/views/negotiations/Index.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/loans',
    name: 'loans',
    component: () => import('@/views/loans/Index.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/merchant',
    name: 'merchant',
    component: () => import('@/views/settings/Index.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/merchant/update',
    name: 'merchant-update',
    component: () => import('@/views/settings/Update.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/Index.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/log-activity',
    name: 'activity',
    component: () => import('@/views/activity/Index.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/notifications/Index.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/premium',
    name: 'merchant-premium',
    component: () => import('@/views/premium/Index.vue'),
    meta: {
      guard: 'auth',
    },
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/Maintenance.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: () => '/404',
  },
]

// function removeQueryParams(to: RouteLocation) {
//   if (Object.keys(to.query).length == 0)
//     return { path: to.path, query: { q: '', status: '', page: 1 } }
// }

export default routes
