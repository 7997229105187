<script setup lang="ts">
import { useModalStore } from '@/stores'
/* components */
import { Button as BButton } from '@/components/ui/button'
import { DialogBase } from '@/components/ui/dialog'

defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
}>()

const modalStore = useModalStore()

const submit = (result: boolean) => modalStore.handleConfirm(result)
const close = () => modalStore.close()
</script>
<template>
  <DialogBase
    :is-open="modalStore.show"
    :is-close="false"
    z-position="z-50"
    @close="close"
  >
    <template #title>
      <div class="font-mono tracking-wide">Peringatan!</div>
    </template>
    <div class="text-justify">
      <p>Apakah Anda Akan Memproses Data ini?</p>
      <p class="font-medium text-destructive">
        Anda Tidak Dapat Membatalkan Tindakan ini!
      </p>
    </div>
    <template #footer>
      <BButton
        id="btn-cancel"
        type="button"
        variant="outline"
        @click="submit(false)"
      >
        Batal
      </BButton>
      <BButton
        id="btn-confirm"
        type="button"
        :loading="modalStore.loading"
        @click="submit(true)"
      >
        Lanjutkan
      </BButton>
    </template>
  </DialogBase>
</template>
