import { defineStore } from 'pinia'
import { reactive, ref, type Ref } from 'vue'
import * as api from '@/api'
import { NotificationModel } from '@/models/notification'

interface DataInterface {
  unread: number
  data: NotificationModel[]
}

export const useNotificationStore = defineStore('notification', () => {
  const intervalId = ref<Ref | null>(null)
  const loading = ref<boolean>(false)
  const popup = ref<boolean>(false)
  const notifications = reactive<DataInterface>({
    unread: 0,
    data: [],
  })
  const REFRESH_INTERVAL = 60 * 1000

  const refresh = async () => {
    try {
      if (loading.value || document.hidden) return

      loading.value = true
      const response = await api.refreshNotification<DataInterface>()
      if (response) {
        notifications.unread = response.unread
        notifications.data = response.data
      }
    } catch (error) {
      console.error('Failed to refresh notifications:', error)
    } finally {
      loading.value = false
      repeatNotification()
    }
  }

  const repeatNotification = () => {
    const repeatNotification = import.meta.env.VITE_NOTIFICATION
    if (!repeatNotification) {
      stopNotification()
      return
    }
    if (intervalId.value) clearInterval(intervalId.value)
    intervalId.value = setInterval(refresh, REFRESH_INTERVAL)
  }

  const stopNotification = () => {
    if (intervalId.value) {
      clearInterval(intervalId.value)
      intervalId.value = null
    }
  }

  const openPopup = (value: boolean) => (popup.value = value)
  const mark = async (id: string) => {
    const response = await api.readNotification(id)
    if (response) refresh()

    return response
  }
  const markAll = async () => {
    const response = await api.readAllNotification()
    if (response) refresh()

    return response
  }

  return {
    intervalId,
    popup,
    notifications,

    refresh,
    repeatNotification,
    stopNotification,
    openPopup,
    mark,
    markAll,
  }
})
