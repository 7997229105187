import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './assets/index.css'

const pinia = createPinia()
const app = createApp(App)

app.provide('baseApi', import.meta.env.VITE_BASE_API)
app.provide('baseStatic', import.meta.env.VITE_BASE_STATIC)
app.provide('buyerUrl', import.meta.env.VITE_BUYER_URL)

app.use(router)
app.use(pinia)
app.mount('#app')
