import { useApi } from '../useApi'

export const csrfCookie = async <T>() => await useApi<T>('/public/csrf-cookie')

export const check = <T>() => useApi<T>('/seller/check')

export const login = async <T>(payload: any) =>
  useApi<T>('/seller/login', {
    payload: payload,
    toastOk: false,
    progress: false,
  })

export const register = async <T>(payload: any) =>
  useApi<T>('/seller/register', {
    payload: payload,
    toastOk: false,
    progress: false,
  })

export const logout = () =>
  useApi('/seller/logout', {
    payload: { type: 'logout', toastOk: false },
  })

export const forgotPassword = <T>(payload: any) =>
  useApi<T>('/seller/password/email', { payload: payload })

export const resetPassword = <T>(payload: any) =>
  useApi<T>('/seller/password/reset', { payload: payload })

export const sendEmailVerification = <T>() =>
  useApi<T>('/seller/seller-email/verification-notification', {
    method: 'POST',
  })

export const emailVerification = <T>(id: string, hash: string, query: string) =>
  useApi<T>(`/seller/seller-email/verify/${id}/${hash}?${query}`)

export const sendMerchantEmailVerification = <T>() =>
  useApi<T>('/seller/merchant-email/verification-notification', {
    method: 'POST',
  })

export const merchantEmailVerification = <T>(
  id: string,
  hash: string,
  query: string,
) => useApi<T>(`/seller/merchant-email/verify/${id}/${hash}?${query}`)
