<script setup lang="ts">
import { computed, useAttrs, type Component, type HTMLAttributes } from 'vue'
import { AsTag, Primitive, type PrimitiveProps } from 'radix-vue'
import { type ButtonVariants, buttonVariants } from '.'
import { cn } from '@/lib/utils'
import { RouterLink } from 'vue-router'

interface Props extends PrimitiveProps {
  to?: string | object
  loading?: boolean
  variant?: ButtonVariants['variant']
  size?: ButtonVariants['size']
  class?: HTMLAttributes['class']
  as?: AsTag | Component
  asChild?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  as: 'button',
})

const attrs = useAttrs()
const _tag = computed(() => {
  if ('href' in attrs && attrs.href !== false) return 'a'
  if (props.to) return RouterLink
  else return props.as
})
</script>

<template>
  <Primitive
    v-bind="attrs"
    :as="_tag"
    :as-child="asChild"
    :class="cn(buttonVariants({ variant, size }), props.class)"
    :to="to"
  >
    <div v-if="loading" class="flex items-center justify-center">
      <div
        class="size-5 animate-spin rounded-full border-4 border-solid border-background border-t-transparent"
        :class="{ 'border-foreground/60': variant === 'outline' }"
      ></div>
    </div>
    <slot v-else></slot>
  </Primitive>
</template>
