export const STATUS_ACTIVE = 'active'
export const STATUS_CLOSED = 'closed'
export const STATUS_VERIFY = 'verify'
export const STATUS_SUSPEND = 'suspended'

export class MerchantClass implements MerchantModel {
  address!: string
  admin_note!: string | null
  bank_account!: BankAccount
  banners: string[] = []
  business_category!: string
  city_id!: string
  city?: Place
  couriers!: string
  district_id!: string
  district?: Place
  documents!: Document
  email!: string
  email_notifications: string[] | null = []
  email_verified_at!: Date | null
  id!: number
  image!: string
  is_umkm!: boolean
  latitude!: string
  longitude!: string
  name!: string
  note!: string
  npwp!: string
  phone!: string
  postal_code!: string
  power: number = 0
  pph_free: boolean | null = null
  premium: number | null = null
  premium_type: number | null = null
  province?: Place
  province_id!: string
  qualified: boolean | null = null
  rating?: number
  review_count?: number
  review_total?: number
  seller_type!: string
  signature!: Signature
  slogan: string = ''
  slug!: string
  sosmed: Sosmed | null = null
  status!: string
  type!: string
  updating: boolean = false
  updating_data: { [key: string]: any } | null = null
  verified_at: Date | string = ''
  village_id!: string
  village?: Place
  errors?: any

  constructor(props?: Partial<MerchantModel>) {
    Object.assign(this, props)
  }

  get isMerchantActived(): boolean {
    return [STATUS_ACTIVE, STATUS_CLOSED].includes(this.status)
  }
  get isMerchantOpen(): boolean {
    return this.status === STATUS_ACTIVE
  }
  get isMerchantVerify(): boolean {
    return this.status === STATUS_VERIFY
  }
  get isMerchantProblem(): boolean {
    return this.status === STATUS_VERIFY && !!this.admin_note
  }
  get isMerchantSuspend(): boolean {
    return this.status === STATUS_SUSPEND
  }
  get isMerchantInvalid(): boolean {
    return Object.keys(this.errors ?? {}).length > 0
  }
  get isMerchantEmailVerified(): boolean {
    return this.email_verified_at != null
  }
  get isMerchantPremium(): boolean {
    return Number(this.premium_type) > 0
  }
}

export interface MerchantModel {
  address: string
  admin_note: string | null
  bank_account: BankAccount
  banners: string[] | null
  business_category: string
  city_id: string
  city?: Place
  couriers: string
  district_id: string
  district?: Place
  documents: Document
  email: string
  email_notifications: string[] | null
  email_verified_at: Date | null
  id: number
  image: string | null
  is_umkm: boolean
  latitude: string
  longitude: string
  name: string
  note: string
  npwp: string
  phone: string
  postal_code: string
  power: number
  pph_free: boolean | null
  premium: number | null
  premium_type: number | null
  province?: Place
  province_id: string
  qualified: boolean | null
  rating?: number
  review_count?: number
  review_total?: number
  seller_type: string
  signature: Signature
  slogan: string
  slug: string
  sosmed: Sosmed | null
  status: string
  type: string
  updating?: boolean
  updating_data?: { [key: string]: any } | null
  verified_at: Date | string
  village_id: string
  village?: Place
  errors?: any
}
interface Place {
  id: string
  name: string
}
interface BankAccount {
  no: string
  name: string
  bank: string
  branch: string
  image: string
}
interface Signature {
  name: string
  position: string
}
interface Document {
  ktp: {
    no: string
    image: string | null
  }
  npwp: {
    no: string
    image: string | null
  }
  siup: {
    no: string
    image: string | null
  }
  pkp: {
    no: string
    image: string | null
  }
}
interface Sosmed {
  ig: string
  fb: string
}
