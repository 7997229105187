import { useApi } from '../useApi'

export const getNotifications = <T>(query: string = '') =>
  useApi<T>(`/seller/notifications${query}`)

export const readNotification = <T>(id: string) =>
  useApi<T>(`/seller/notifications/${id}/read`)

export const readAllNotification = <T>() =>
  useApi<T>('/seller/notifications/read-all')

export const refreshNotification = <T>() =>
  useApi<T>('/seller/notifications/refresh')

export const preferences = <T>() =>
  useApi<T>('/seller/notifications/preferences')

export const updatePreferences = <T>(payload: any) =>
  useApi<T>('/seller/notifications/preferences', {
    payload: payload,
    method: 'PUT',
  })
