export const isBrowser = function () {
  let product
  if (
    typeof navigator !== 'undefined' &&
    ((product = navigator.product) === 'ReactNative' ||
      product === 'NativeScript' ||
      product === 'NS')
  ) {
    return false
  }
  return typeof window !== 'undefined' && typeof document !== 'undefined'
}

export const getCookie = (key: string): string => {
  let cookie = ''
  if (isBrowser()) {
    const cookies = document.cookie
    const value =
      decodeURIComponent(
        cookies.replace(
          new RegExp(
            '(?:(?:^|.*;)\\s*' +
              encodeURIComponent(key) +
              '\\s*\\=\\s*([^;]*).*$)|^.*$',
          ),
          '$1',
        ),
      ) || ''
    cookie = value
  }

  return cookie
}

export const removeCookie = (key: string) => {
  document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}
