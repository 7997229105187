<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '@/lib/utils'

const props = defineProps<{ class?: HTMLAttributes['class'] }>()
</script>

<template>
  <div
    :class="
      cn(
        'mt-4 flex flex-col-reverse sm:flex-row sm:justify-end gap-2',
        props.class,
      )
    "
  >
    <slot />
  </div>
</template>
