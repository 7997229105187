<script setup lang="ts">
/* components */
import {
  Dialog,
  DialogClose,
  DialogScrollContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '.'
import { ref, useSlots, watch } from 'vue'

const emit = defineEmits<{
  (e: 'submit', payload: any): void
  (e: 'close'): void
}>()

const {
  isOpen = false,
  isClose = true,
  showCloseIcon = true,
  contentClass = '',
  zPosition = '',
  title = '',
  description = '',
} = defineProps<{
  isOpen: boolean
  isClose?: boolean
  showCloseIcon?: boolean
  contentClass?: string
  zPosition?: string
  title?: string
  description?: string
}>()

const slots = useSlots()

const isDialogOpen = ref<boolean>(isOpen)

watch(
  () => isOpen,
  (val) => {
    isDialogOpen.value = val
  },
)
watch(
  () => isDialogOpen.value,
  (val) => (!val ? emit('close') : ''),
)
</script>
<template>
  <Dialog v-model:open="isDialogOpen">
    <DialogScrollContent
      :class="contentClass"
      :z-position="zPosition"
      :show-close-icon="showCloseIcon"
    >
      <DialogHeader>
        <DialogTitle v-if="title || slots.title">
          <slot name="title">{{ title }}</slot>
        </DialogTitle>
        <DialogDescription>
          {{ description }}
        </DialogDescription>
      </DialogHeader>
      <slot></slot>
      <DialogFooter>
        <DialogClose v-if="isClose" as-child>
          <slot name="footer"></slot>
        </DialogClose>
        <slot v-else name="footer"></slot>
      </DialogFooter>
    </DialogScrollContent>
  </Dialog>
</template>
