import { defineStore } from 'pinia'
import { ref, shallowRef, watch, type Component } from 'vue'

export const useModalStore = defineStore('modal', () => {
  const loading = ref<boolean>(false)
  const show = ref<boolean>(false)
  const component = shallowRef<Component | null>(null)
  const resolve = shallowRef<((value: boolean) => void) | null>(null)

  const open = () => (show.value = true)

  const close = () => (show.value = false)

  const confirm = (): Promise<boolean> => {
    return new Promise((res) => {
      resolve.value = res
      open()
    })
  }

  watch(
    () => show.value,
    (val) => val === false && handleConfirm(val),
  )

  const handleConfirm = (result: boolean) => {
    if (resolve.value) {
      resolve.value(result)
    }
    close()
  }

  return {
    loading,
    show,
    component,
    open,
    close,
    confirm,
    handleConfirm,
  }
})
