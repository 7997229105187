import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useAuthStore, useNotificationStore } from '@/stores'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach(async (_routeTo, _routeFrom, next) => {
  const authStore = useAuthStore()
  const notifStore = useNotificationStore()

  const guarded = _routeTo.matched.some((route) => route.meta.guard)
  if (guarded) {
    const guard = _routeTo.meta.guard
    const isGuardAuth = guard === 'auth'
    const isGuardGuest = guard === 'guest'

    await authStore.fetch()
    if (isGuardAuth) {
      if (!authStore.userLogin) {
        next({ name: 'login' })
      } else if (!authStore.userEmailVerified) {
        if (_routeTo.name !== 'seller-verification')
          next({ name: 'seller-verification' })
        else next()
      } else {
        const hasMerchant = authStore.hasMerchant
        if (!hasMerchant)
          if (_routeTo.name !== 'merchant-update')
            next({ name: 'merchant-update' })
          else next()
        else {
          await authStore.fetchMerchant()
          const merchantVerify = authStore.merchant?.isMerchantVerify
          if (merchantVerify) {
            if (_routeTo.name !== 'merchant-update')
              next({ name: 'merchant-update' })
            else next()
          } else {
            if (authStore.merchant?.isMerchantPremium)
              localStorage.setItem('premium_merchant', '1')
            else localStorage.removeItem('premium_merchant')

            notifStore.refresh()
            next()
          }
        }
      }
    } else if (isGuardGuest) {
      if (authStore.userLogin) {
        next({ name: 'dashboard' })
      } else next()
    } else next()
  } else next()
})

router.beforeResolve(async (_routeTo, _routeFrom, next) => {
  const authStore = useAuthStore()
  if (authStore.user && !authStore.merchant) authStore.fetchMerchant()

  next()
})

export default router
