import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'

type ToastType = 'success' | 'error';

interface FormData {
  title: string
  message: string
}

interface FormInterface {
  state: ToastType | null
  data: FormData
}

export const useToastStore = defineStore('toast', () => {
  const loading = ref<boolean>(false)
  const state = ref<ToastType | null>(null)
  const data = reactive<FormData>({
    title: '',
    message: '',
  })

  const fire = (
    payload: FormInterface['data'] | null,
    type: ToastType | null = 'error',
  ) => {
    state.value = type
    setTimeout(() => {
      if (payload) {
        data.title = payload.title
        data.message = payload.message
      }

      setTimeout(() => {
        destroyToast()
      }, 5000)
    }, 200)
  }

  const setLoading = (isLoading: boolean) => (loading.value = isLoading)

  const destroyToast = () => {
    data.title = ''
    data.message = ''
    state.value = null
  }

  return {
    data,
    state,
    fire,
    destroyToast,
    loading,
    setLoading,
  }
})
