import { useAuthStore, useModalStore, useToastStore } from '@/stores'
import { shallowRef, watch, toRef, toValue } from 'vue'
import type { Ref } from 'vue'
import { getCookie } from './cookie'

export type MaybeRef<T> = T | Ref<T>
export type MaybeRefOrGetter<T> = MaybeRef<T> | (() => T)

export interface UseApiOptions {
  payload?: any | null
  progress?: boolean
  toastOk?: boolean
  toastError?: boolean
  immediate?: boolean
  prefix?: string
  method?: string
  confirm?: boolean
}

export interface UseApiReturn<T> {
  data: Ref<T | null>
  execute: () => Promise<T | null>
}

/**
 * useApi
 *
 * @param path
 * @param options
 */
export function useApi<DataT>(
  path: MaybeRefOrGetter<string>,
  options?: UseApiOptions,
): UseApiReturn<DataT> & PromiseLike<DataT | null> {
  const toastStore = useToastStore()
  const data = shallowRef<DataT | null>(null)
  const actionMethod = ['POST', 'PUT', 'DELETE']

  // const loading = useLoadingStore()
  const execute = async () => {
    const baseUrl = import.meta.env.VITE_BASE_API
    const method = options?.method ?? (options?.payload ? 'POST' : 'GET')
    const progress = options?.progress ?? true
    const headers: HeadersInit = {
      Accept: 'application/json, text/plain, */*',
    }

    const xsrfCookie = getCookie('XSRF-TOKEN')
    if (xsrfCookie) headers['X-XSRF-TOKEN'] = xsrfCookie

    let body = options?.payload
    if (options?.payload) {
      if (options.payload instanceof FormData) {
        // headers['Content-Type'] = 'application/x-www-form-urlencoded'
      } else {
        headers['Content-Type'] = 'application/json'
        body = JSON.stringify(options.payload)
      }
    }

    if (actionMethod.includes(method) && options?.confirm) {
      const modalStore = useModalStore()
      const confirm = await modalStore.confirm()
      if (!confirm) return null
    }

    if (actionMethod.includes(method) && progress) {
      toastStore.setLoading(true)
    }

    return new Promise<DataT | null>((resolve) => {
      const localPath =
        baseUrl + (options?.prefix ? `/${options.prefix}` : '') + toValue(path)
      fetch(localPath, {
        method: method,
        credentials: 'include',
        headers: headers,
        body: body,
      })
        .then(async (response) => {
          if (!response.ok) {
            if (response.status == 401) {
              const auth = useAuthStore()
              auth.clearUser()
            } else if (response.status == 503) {
              window.location.href = 'maintenance'
              return
            } else {
              const data = await response.json()
              if (options?.toastError === undefined) {
                toastStore.fire({
                  title: 'Oopss! Error',
                  message: data.message || response.statusText,
                })
              }
            }
            data.value = null
            resolve(null)
            return
          } else if (response.status === 204) {
            resolve(null)
            return
          }

          const responseData = await response.json()
          data.value = responseData
          if (method != 'GET' && options?.toastOk === undefined) {
            toastStore.fire({
              title: 'Data Berhasil diproses',
              message: responseData.message,
            }, 'success')
          }
          resolve(responseData)
        })
        .catch((error) => {
          toastStore.fire({ title: 'Error', message: error.message })
          resolve(null)
        })
        .finally(() => {
          toastStore.setLoading(false)
        })
    })
  }

  watch(toRef(path), () => execute())

  if (options?.immediate) Promise.resolve(execute())

  return {
    data,
    execute,
    then(onfulfilled, onrejected) {
      return execute().then(onfulfilled, onrejected)
    },
  }
}
