import { useApi } from '../useApi'

export const getDashboard = <T>() => useApi<T>('/seller/dashboard/index')

export const getProfile = <T>() => useApi<T>('/seller/merchant')

export const postMerchant = <T>(payload: any) =>
  useApi<T>('/seller/merchant', { payload: payload })

export const putMerchant = <T>(payload: any) =>
  useApi<T>('/seller/merchant', { payload: payload, method: 'PUT' })

export const putMerchantStatus = <T>(payload: any) =>
  useApi<T>('/seller/merchant/status', { payload: payload })

export const getActivity = <T>(query: string = '') =>
  useApi<T>(`/seller/merchant/log/activities${query}`)

export const getUsers = <T>(query: string = '') =>
  useApi<T>(`/seller/merchant/users${query}`)

export const addUser = <T>(payload: any) =>
  useApi<T>('/seller/merchant/users', {
    payload: payload,
  })

export const suspendUser = <T>(userId: number, payload: any) =>
  useApi<T>(`/seller/merchant/${userId}/update-active`, {
    payload: payload,
    confirm: true,
  })

export const updateUserRoles = <T>(userId: number, payload: any) =>
  useApi<T>(`/seller/merchant/${userId}/update-roles`, {
    payload: payload,
  })
